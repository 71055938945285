
import { defineComponent } from "vue-demi";
import InfoPolicy from "@/components/info/Policy.vue";

export default defineComponent({
  name: "InfoPolicyView",
  components: {
    InfoPolicy,
  },
});
